

.foot{

    width: calc(100vw - 19vw);
    padding: 0 9.75vw 51px 9.75vw;

    &__box{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__txt{
        margin: 28px 0 18px 0;
    }
    &__title{
        padding: 0;
    }
}