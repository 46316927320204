@font-face{
    font-family: 'ekors';
    src: url(../../assets/font/ekors.otf);
  
}
@font-face{
    font-family: 'roboto slab';
    src: url(../../assets/font/RobotoSlab-VariableFont_wght.ttf);
  
}


.sect{

    &__title{
        font-family: 'ekors';
        font-size: 28px;
        color: $c-text;

        &--2{
            padding: 0 0 0 8px;
            letter-spacing: 0.12em;
        }
    }


    &__txt{
        font-family: 'roboto slab';
        font-size: 16px;
        color: $c-text;
        letter-spacing: 0.12em;
        line-height: 140%;
        font-weight: 200;

        &--ecartSimple{ 
            margin: 28px 0;
        }

        &--ecartDouble{

            margin: 51px 0;
        }
        &--sousTitre{
            text-transform: uppercase;
            font-size: 21px;
            position: absolute;
            bottom: 0px;
            width: 100%;
            text-align: center;
            z-index: 8;
            height: 37px;
            padding: 15px 0 0 0 ;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, #000000 100%);
        }
        &--clickMe{
            font-size: 16px;
            position: relative;
            bottom: 72px;
            width: 100%;
            text-align: center;
            opacity: 0.8;
        }
        &--low{
            bottom: 8px;
        }
    }
    &__interlineBR{
        display: block;
        height: 28px;
    }
    &__txtBtn{
        font-weight: 800;
    }
}

.overlay{
    &__txt{
        font-family: 'roboto slab';
        font-size: 16px;
        color: $c-text;
        letter-spacing: 0.12em;
        line-height: 140%;
        font-weight: 200;

    }
}