

.head{
    display: flex;
    flex-direction: row;
    height: 46px;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    padding: 0 38px;
    background-color: #000;


    &__txt{
        font-family: 'roboto slab';
        font-weight: 200;
        letter-spacing: 0.12em;
        font-size: 14px;
        text-align: center;
        color: $c-text;
        width: calc(100% - 80px);
        padding: 15px 0;
    }

    &__btn{
        
        width: 30px;
        height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: none;
        background-color: transparent;
        display: none;
        &>span{
            width: 100%;
            height: 3px;
            border-radius: 6px;
            background-color: #FAF4EC50;

        }
    }
}