@import 'utils/variables';
@import 'utils/font';
@import 'components/type';
@import 'components/header';
@import 'components/content';
@import 'components/sliderBox';
@import 'components/foot';
@import 'components/overlay';

*{
    margin: 0;
    padding: 0;
    box-sizing: content-box;
}


body{
   box-sizing: content-box;
   background-color: #000;
}

main{
    margin: 46px 0;
    width: 100vw;

}