

.slider{


    &__box{
        width: 100vw;
        min-width: 390px;
        height: 100%;
        position: relative;

        &--none{
            display: none;
        }
    }

    &__img{
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        image-rendering: pixelated;
        object-fit: cover;
    }

    &__controlBtn{
        width: 154px;
        height: 9px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 14px auto 0 auto;
        align-items: center;


    }

    &__btn{
        width: 16px;
        height: 9px;
        border-radius: 3px;
        background-color: #FAF4EC30;
        cursor: pointer;

        &--actif{
            width: 53px;
            background-color: #FAF4EC;
        }
    }

    &__video{

       width: 100%;
       height: 90%;
       object-fit: cover;
    }
}