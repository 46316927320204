.sect__overlay {

    width: 100vw;
    height: 100vh;
    z-index: 28;
    position: fixed;
    top: 0;
    left: 0;
    border-top: 2px solid $c-text;
    overflow-x: scroll;
    display: flex;
    align-items: center;
    background-color: #000;
    scrollbar-width: thin;
    scrollbar-color: #FF9900;

}

.sect__overlay {

    &::-webkit-scrollbar {
        height: 20px;
    }

    &::-webkit-scrollbar-track {
        background: #ffffff00;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #FF990080;
        border-radius: 20px;
        border: 8px solid #000000;

    }
}




.overlay {
    &__close {
        display: none;
    }

    &__btnClose {
        position: fixed;
        top: 16px;
        right: 16px;
        cursor: pointer;
    }

    &__img {
        //height: 100%;
        //width: 100%;
        background-repeat: no-repeat;
        object-fit: cover;
        background-position: left;
        margin: auto 0;

        &--small {
            min-width: 100vw;
            width: auto;
        }
    }

    &__txt {
        font-family: 'roboto slab';
        color: $c-text;
        letter-spacing: 0.12em;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 21px;
        position: fixed;
        bottom: 60px;
        width: 100%;
        text-align: center;
        z-index: 8;
        height: 37px;
        padding: 15px 0 0 0;
    }

    &__video{
        background-repeat: no-repeat;
        object-fit: cover;
        background-position: left;
        margin: auto 0;
    }
}


@media(min-width:700px){

    .overlay{

        &__img{
            &--small {
                min-width: 80vw;
                width: auto;
                height: 90%;
                margin: 0 auto;
               
            }
        }
    }
}