

.sect{

    margin: 97px 0;
    width:calc(100% - 78px);
    height: calc(100vh - 110px);
    min-height: 600px;
    background-image: url(../../assets/images/bgIntro.png);
    background-repeat: no-repeat;
    background-position: center;
    padding: 0 38px;

    &--seeMore{
        height: 160vh;
        max-height: 1042px;
    }
    &__seeMore{
        display: none;

        &--actif{
            display: block;
           
        }
    }
    &__boxLien{
        height: 87px;
        width: 174px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 28px auto 51px auto;

        &>a{
            &:nth-child(2){
                margin: -8px 0 0 0 ;
            }
        }
    }
    &__animBoxTop{
        display: flex;
        flex-direction: column;
         align-items: center;
    }
    //background-color: red;

    &--imgSlide{
        padding: 0 0 ;
        width: 100vw;
        margin: 124px 0;
        height: 100vh;
    }

    /// slider des images 

    &__sliderImage{
        height: 85vh;
        width: 100vw;
        //width: 100vw;
        display: flex;
        flex-direction: row;
        //overflow-x: hidden;
        border-top: 2px solid $c-text;
        overflow-y: hidden;
        overflow-x: scroll;
    }
}

.animTel{
    width: 41px;
    height: 68px;
    border: 2px solid $c-text;
    border-radius: 8px;
    margin: 0 0 12px 0;
    &::after{
        content: "" ;
        width: 4px;
        height: 20px;
        background-color: $c-text; 
        border-radius: 2px;
        position: relative;
        display: block;
        top: 10px;
        left: 18px;
        animation: animationTel 6s infinite;

    }

}

@keyframes animationTel {
    from{
        transform: translateY(0);
    }
    20%{
        transform: translate3d(-10px, 10px ,0);
    }
    40%{
        transform: translate3d(-10px, 20px ,0);
        background-color: #FF9900;
    }
    60%{
        transform: translate3d(14px, 10px ,0);
    }
    80%{
        transform: translateY(30px);
    }
    to{
        transform: translateY(0);
        background-color: $c-text;
    }
    
}